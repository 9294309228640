<template>
     <div class="d-flex justify-content-center flex-column">
<div class="mb-3">
<h4 class="mb-2">Band</h4>
  <div class="slider-div mt-4">
  <input type="range" min="29" max="50"  class="slider" v-model="x" @input="moveRangeThumb">
  <div class="range-text">{{ x }} in</div>
</div>
</div>

<div class="mt-3 mb-4">
  <h4 class="mb-3">Bust</h4>
    <div class="slider-div mt-4">
    <input type="range" min="29" max="60"  class="slider" id="slider-1" v-model="y" @input="moveRangeThumb">
    <label for="slider-1" class="range-text">{{ y }} in</label>
  </div>
  </div>
  <div class="rectugular-button d-flex justify-content-center mt-2">
    <button type="button" class="btn recalculate-btn" @click.stop="recalculate()">Calculate</button>
  </div>
  <div class="recomended-result text-center w-100 mt-4">
    <div class="" v-if=" this.result">
        <div class="h4 ">Your standard size</div>
    <div class="h1 font-weight-bold recomended-text" style="color:#72A9DA">{{ result }}</div>
</div>
    <div class="h4" v-if="size_error">Size is not supported</div>
  </div>
</div>
</template>
<script>
export default {
    props:{
        options:Array
    },
    watch:{
       
    },
    data(){
        return{
            x:29,
            y:29,
            result:'',
            size:['A','B','C','D','DD','DDD','G','G','H','I/J'],
            range:0,
            size_error:false,
        }
    },
    methods:{
        moveRangeThumb(e){
          let maxValue= e.target.getAttribute("max");
          let minValue= e.target.getAttribute("min");
          let currentValue= e.target.value;
          let thumbwidth= 30;
          let newVal = (((currentValue - minValue) / (maxValue-minValue)) * (e.target.clientWidth-thumbwidth)+thumbwidth/2 )+thumbwidth/2;
          currentValue==minValue?newVal+=15:''
          currentValue==maxValue?newVal-=22:''
          let textLabel= e.target.nextElementSibling
          textLabel.style.left =  `calc(${newVal}px )`;
        },
      async  recalculate(){
            this.size_error=false;
            const x=parseInt(this.x);
            const y=parseInt(this.y);
            let band= x+3;
            band=band%2==0?band:band-1;
            let bust = y-x;
            this.result=band+' '+this.size[bust-1];
            const selectedOption=this.options.find(op=>op.value==this.result.replace(' ','')||op.value.replace(' ','')==this.result.replace(' ',''))
            if(!this.size[bust-1])
            {
                this.size_error=true
                this.result=''
                this.$toasted.show("Size is not supported.", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
            }
            else if(selectedOption)
            {

                this.size_error=false
                this.$emit('dairyFairySubmit',selectedOption)
                // setTimeout(() => {
                //     
                // }, 2000);
                
            }
           else{
            this.$toasted.show("Size is not available on stock", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
           }
            
            // if(!this.size[bust-1]||true)
            // {
            //     this.$toasted.show("Not Valid Size.", {
            //   theme: "toasted-primary",
            //   position: "bottom-center",
            //   duration: 2000,
            // });
            
            // }
            // else
            // 
        }
    }
}
</script>
<style scoped>
  .slidecontainer {
    width: 100%!important;
    transition: all ease 0.3s;
  }
 
 input[type="range"].slider {
    -webkit-appearance: none!important;
    width: 100%!important;
    height: 3px!important;
    background: black!important;
    outline: none!important;
    min-height: 3px!important;
    -webkit-transition: .2s!important;
    transition: opacity .2s!important;
  }
  .slider-div{
    display:flex!important;
    align-items: center!important;
    /* width: 100%!important; */
    position: relative!important;
    
  }
  .slider-div::before{
    content: ''!important;
    width: 0!important;
      height: 0!important;
      border-top: 5px solid transparent!important;
      border-right: 10px solid #000000!important;
      border-bottom: 5px solid transparent!important;
    position: relative!important;
    right: -2px!important;
    z-index: -11111!important;
  }
  .slider-div::after{
    content: ''!important;
    width: 0!important;
      height: 0!important;
      border-top: 5px solid transparent!important;
      border-left: 10px solid #000000!important;
      border-bottom: 5px solid transparent!important;
    position: relative!important;
    right: 2px!important;
    cursor: pointer!important;
    z-index: -11111!important;
    overflow: hidden;
  }
  .slider:hover {
    opacity: 1!important;
  }
  
  .slider::-webkit-slider-thumb {
    position: relative!important;
    -webkit-appearance: none!important;
    appearance: none!important;
    width: 60px!important;
    height: 40px!important;
  
    background: transparent!important;
    cursor: pointer!important;
    z-index: 22222!important;
   
  }
  
  .slider::-moz-range-thumb {
    position: relative!important;
    -moz-appearance: none!important;
   
    width: 88px!important;
    height: 46px!important;
    background: transparent!important;
    cursor: pointer!important;
    z-index: 22222!important;
  }
  .range-text {
    
    position: absolute!important;
    color: white!important;
    left: 44px;
    transform: translate(0%);
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    background: rgb(0, 0, 0)!important;
    text-align: center!important;
    line-height: 1!important;
    width: 60px!important;
    height: 40px!important;
    font-weight: 500; 
    font-size: 18px;
    transform: translate(-32px, 2px)!important;
    -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  }
  .recalculate-btn{
    padding: 3px 80px!important;
      font-size: 30px!important;
      background: #8585855c!important;
      font-weight: 500!important;
  }
  .recalculate-btn:focus{
    box-shadow: 0 0 0 0.2rem rgb(5 4 4 / 98%)!important;
  }
  </style>
